import React from 'react'
import { QadhaButtonHolder, QadhaLastUpdated, QadhaSummaryButton, QadhaSummaryHolder, QadhaSummaryText, QadhaTitle } from './styles/styled.container'
import {AyahArabic, AyahEnglish} from "./styles/styled.container"

function QadhaSummary(props) {
    const {total, addDay, removeDay, lastUpdated} = props;

  return (
    <QadhaSummaryHolder>
        <AyahArabic>إِنَّ ٱلصَّلَوٰةَ كَانَتْ عَلَى ٱلْمُؤْمِنِينَ كِتَـٰبًۭا مَّوْقُوتًۭا</AyahArabic>
        <AyahEnglish>Indeed, performing prayers is a duty on the believers at the appointed times.</AyahEnglish>
        
        <QadhaSummaryText style={{'marginTop': '10px'}}>You have {total} qadha's remaining</QadhaSummaryText>
        <QadhaLastUpdated>Last updated on {lastUpdated}</QadhaLastUpdated>
        <QadhaButtonHolder style={{'marginTop': '30px'}}>
            <QadhaSummaryButton onClick={() => addDay()}>Add 1 day</QadhaSummaryButton>
            <QadhaSummaryButton onClick={() => removeDay()}>Reduce 1 day</QadhaSummaryButton>
        </QadhaButtonHolder>
    </QadhaSummaryHolder>
  )
}

export default QadhaSummary