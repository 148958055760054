import React, { useEffect, useState } from 'react'
import { Container, HeaderTitle, PageContainer, QadhaButtonHolder, QadhaButtonSmall, QadhaCount, QadhaCountButton, QadhaItem, QadhaItemHolder, QadhaTitle } from './styles/styled.container'
import {GrAdd} from "react-icons/gr"
import {IoMdRemove} from "react-icons/io"

function Qadha(props) {

    const {title, salaahElements, setSalaahElements, total} = props;

    function handleAdd(count) {
      if(title === "Fajr")
        setSalaahElements(prevState => ({...prevState, fajrTotal: prevState.fajrTotal + count }))
      else if(title === "Dhuhr")
        setSalaahElements(prevState => ({...prevState, dhuhrTotal: prevState.dhuhrTotal + count }))
      else if(title === "Asr")
        setSalaahElements(prevState => ({...prevState, asrTotal: prevState.asrTotal + count }))
      else if(title === "Maghrib")
        setSalaahElements(prevState => ({...prevState, maghribTotal: prevState.maghribTotal + count }))
      else if(title === "Esha")
        setSalaahElements(prevState => ({...prevState, eshaTotal: prevState.eshaTotal + count }))
      else if(title === "Witr")
        setSalaahElements(prevState => ({...prevState, witrTotal: prevState.witrTotal + count }))
    }

    function handleRemove(count) {
      if(title === "Fajr")
      {
        if(salaahElements.fajrTotal > 0)
          setSalaahElements(prevState => ({...prevState, fajrTotal: prevState.fajrTotal - count }))
      } else if(title === "Dhuhr") {
          if(salaahElements.dhuhrTotal > 0)
            setSalaahElements(prevState => ({...prevState, dhuhrTotal: prevState.dhuhrTotal - count }))
      } else if(title === "Asr") {
          if(salaahElements.asrTotal > 0)
            setSalaahElements(prevState => ({...prevState, asrTotal: prevState.asrTotal - count }))
      } else if(title === "Maghrib") {
          if(salaahElements.maghribTotal > 0)
            setSalaahElements(prevState => ({...prevState, maghribTotal: prevState.maghribTotal - count }))
      } else if(title === "Esha") {
          if(salaahElements.eshaTotal > 0)
            setSalaahElements(prevState => ({...prevState, eshaTotal: prevState.eshaTotal - count }))
      } else if(title === "Witr") {
        if(salaahElements.witrTotal > 0)  
          setSalaahElements(prevState => ({...prevState, witrTotal: prevState.witrTotal - count }))
      }
    }

    function updateTotal(value) {

      if(value === "") value = 0;
      else value = parseInt(value);
    
      if(title === "Fajr") {
        setSalaahElements(prevState => ({...prevState, fajrTotal: value }))
      } else if(title === "Dhuhr")
        setSalaahElements(prevState => ({...prevState, dhuhrTotal: value }))
      else if(title === "Asr")
        setSalaahElements(prevState => ({...prevState, asrTotal: value }))
      else if(title === "Maghrib")
        setSalaahElements(prevState => ({...prevState, maghribTotal: value }))
      else if(title === "Esha")
        setSalaahElements(prevState => ({...prevState, eshaTotal: value }))
      else if(title === "Witr")
        setSalaahElements(prevState => ({...prevState, witrTotal: value }))

    }

    const handleFocus = (event) => event.target.select();

  return (
    <QadhaItemHolder>
                <QadhaTitle>{title}</QadhaTitle>
                <QadhaItem>
                    <QadhaCountButton onClick={() => handleAdd(1)}><GrAdd /></QadhaCountButton>
                    <QadhaCount onFocus={handleFocus} type="number" value={total} onChange={e => updateTotal(e.target.value)}  />
                    <QadhaCountButton onClick={() => handleRemove(1)}><IoMdRemove /></QadhaCountButton>
                </QadhaItem>
                <QadhaButtonHolder>
                    <QadhaButtonSmall onClick={() => handleAdd(5)}>+5</QadhaButtonSmall>
                    <QadhaButtonSmall onClick={() => handleRemove(5)}>-5</QadhaButtonSmall>
                    
                </QadhaButtonHolder>
    </QadhaItemHolder>
  )
}

export default Qadha