import styled from "styled-components";
import { Colors } from "./styled.container";

export const NavContainer = styled.nav`
    width: 100%;
    display: flex;
    height: 60px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 0px;
    z-index: 300;
`

export const NavInnerContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 50px;
`

export const NavExpandedContainer = styled.div`
    display: ${props => props.showExpanded ? "flex" : "none"};
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255, 0.9); 
    z-index: 200;
    overflow-x: hidden; 
    overflow-y: hidden;
    position: fixed;
    top: 0;
    left: 0;

`

export const NavLeftContainer = styled.div`
    display: flex;
    flex: 1;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    justify-content: flex-start;
`

export const NavLeftContainerText = styled.h2`
    font-size: 1.5rem;
    font-weight: 800;
    color: ${Colors.dark_grey};
    margin: 20px 0px;
    margin-left: 10px;

    @media(max-width: 400px) {
        font-size: 1rem;
    }
`

export const NavRightContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
`

export const NavQuoteContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
`

export const NavRightIconContainer = styled.div`
    display: none;
    z-index: 301;
    color: white;
    background-color: #ebeff2;
    border-radius: 50px;
    padding: 5px;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 800px) {
        display: flex;
        margin-right: 20px;
    }
`

export const NavLogoHolder = styled.div`

    display: flex;
    z-index: 300;
    align-items: center;
    justify-content: flex-start;
`

export const NavLogo = styled.img`
    width: 50px;
    height: 50px;
    z-index: 301;
`

export const NavItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 800px) {
        display: none;
    }
`
export const NavExpandedItemContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

`

export const NavExpandedItem = styled.div`
    font-size: 1.6rem;
    margin-top: 20px;
    font-weight: 700;
    color: ${Colors.dark_grey};
    text-align: center;
    transition: all 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        color: ${Colors.dark_highlight};
        font-weight: 700;
    }
`

export const NavItem = styled.div`
    margin-right: 30px;
    font-size: 0.8rem;
    color: #2b2b2c;
    text-transform: lowercase;
    font-weight: 600;
    transition: all 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        color: #cdcdcd;
    }
`
