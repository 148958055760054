import styled from "styled-components";

export const Colors = {
    blue: "#4361ee",
    light_grey: "#f8f9fa",
    dark_grey: "#212529",
    white: "white",
    subtitle: "#f72585",
    card_background: "#f8f9fa",
    highlight: "#52b788",
    dark_highlight: "#11FFBD",
    light_highlight: "#AAFFA9",
    disabled: "#ced4da",
    navbar: "#EBEFF2"
}

export const PageContainer = styled.div`
    display: flex;
    width: 100%;
    // removed height 100% because on storevisitform margin bottom not working
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    width: 100%;
    height: 100%;
`

export const QadhaSummaryHolder = styled.div`
    width: 100%;
    padding: 30px 5px;
    margin-bottom: 20px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background-color: "#f7f7f7";
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    ${({calculator}) => calculator && `
        background: #AAFFA9;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #11FFBD, #AAFFA9);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #11FFBD, #AAFFA9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    `}

`

export const QadhaSummaryText = styled.div`
    width: 100%;
    margin-top: 5px;
    padding: 0px 20px;
    text-align: center;
    font-weight: 700;
    font-size: 1.4rem;
`

export const QadhaLastUpdated = styled.div`
    width: 100%;
    margin-top: 5px;
    font-weight: 500;
    color: ${Colors.dark_grey};
    text-align: center;
    font-size: 0.8rem;
`

export const QadhaSummaryButton = styled.div`
    border-radius: 20px;
    padding: 20px 50px;
    font-weight: 600;
    margin: 0px 5px;
    height: 30px;
    font-size: 0.7rem;
    
    background-color: ${Colors.dark_highlight};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    color: ${Colors.dark_grey};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background-color: ${Colors.light_highlight};
    }

    @media (max-width: 500px) {
        padding: 20px 30px;
        font-size: 0.7rem;
    }
`

export const QadhaItemHolder = styled.div`
    width: 100%;
    height: fit-content;
    padding: 10px 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: ${Colors.card_background};
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`

export const QadhaTitle = styled.div`
    width: 100%;
    font-size: 1.4rem;
    font-weight: 600;
    text-align: center;
`

export const QadhaItem = styled.div`
    width: 100%;
    padding: 20px 0px;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`

export const QadhaCount = styled.input`
    background-color: ${Colors.white};
    flex: 1;
    border-radius: 10px;
    text-align: center;
    margin: 0px 10px;
    border: 0;
    outline: 0;
    font-size: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    @media (max-width: 500px) {
        width: 120px;
    }
`

export const QadhaCountButton = styled.div`
    border-radius: 50%;
    width: 40px;
    padding: 10px;
    height: 40px;
    box-shadow: rgba(6, 24, 44, 0.1) 0px 0px 0px 2px, rgba(6, 24, 44, 0.25) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background-color: ${Colors.dark_highlight};
    }

    @media(max-width: 500px) {
        width: 30px;
        height: 30px;
    }
`

export const QadhaButtonHolder = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
`

export const QadhaButtonSmall = styled.div`
    border-radius: 20px;
    padding: 20px 50px;
    margin: 0px 10px;
    height: 30px;
    font-size: 0.8rem;
    background-color: ${Colors.dark_highlight};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    color: ${Colors.dark_grey};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background-color: ${Colors.light_highlight};
    }


`

export const Button = styled.div`
    background-color: ${Colors.blue};
    width: fit-content;
    padding: 10px 30px;
    text-align: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: inline-block;
    font-size: 0.8rem;
    cursor: pointer;
    color: ${Colors.white};
`

export const Text = styled.div`
    color: ${Colors.dark_grey};
    font-size: 0.8rem;
    flex: 1;
`

export const HeaderTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 800;
    height: auto;
    color: ${Colors.dark_grey};
    margin: 10px 0px;
    margin-bottom: 20px;
`

export const HeaderSubTitle = styled.div`
    font-size: 1rem;
    font-weight: 700;
    color: ${Colors.dark_grey};
    margin-bottom: 0px;
`

export const ErrorContainer = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    background-color: ${Colors.light_grey};
    align-items: center;
    z-index: 2;
    width: 100%;
    height: 100%;
`

export const ErrorContainerText = styled.p`
    font-size: 1.2rem;
    text-align: center;
    font-weight: 700;
    color: ${Colors.dark_grey};
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
`

export const ErrorText = styled.div`
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: flex-end;
    font-size: 0.7rem;
    color: ${Colors.highlight};
`

export const ButtonApply = styled.div`
    width: 100%;
    border-radius: 10px;
    max-width: 600px;
    position: sticky;
    bottom: 0;
    left:  0;
    margin: 20px 0px;
    text-align: center;
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    font-size: 0.9rem;
    font-weight: 500;
    
    background: #AAFFA9;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #11FFBD, #AAFFA9);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #11FFBD, #AAFFA9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    color: ${Colors.dark_grey};
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        color: ${Colors.white};
    }
`

export const EmptyTotalContainer = styled.div`
    width: 100%;
    padding: 30px 5px;
    margin-bottom: 20px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    background: #AAFFA9;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #11FFBD, #AAFFA9);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #11FFBD, #AAFFA9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`

export const EmptyContainerText = styled.div`
    width: 100%;
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 0px 20px;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
`

export const EmptyContainerButton = styled.div`
    border-radius: 20px;
    padding: 20px 50px;
    width: 80%;
    margin: 0px 5px;
    height: 30px;
    font-size: 1rem;
    font-weight: 600;
    background-color: ${Colors.card_background};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    color: ${Colors.dark_grey};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background-color: ${Colors.navbar};
    }

    @media (max-width: 500px) {
        padding: 20px 30px;
        font-size: 0.9rem;
    }
`

export const InstallButton = styled.div`
    border-radius: 10px;
    padding: 20px 50px;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    height: 30px;
    font-size: 0.9rem;
    font-weight: 600;
    
    background: #AAFFA9;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #11FFBD, #AAFFA9);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #11FFBD, #AAFFA9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    color: ${Colors.dark_grey};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background-color: ${Colors.navbar};
    }

    @media (max-width: 500px) {
        padding: 20px 30px;
        font-size: 0.9rem;
    }
`

export const AyahArabic = styled.div`
    font-weight: 700;
    font-size: 1.3rem;
    text-align: center;

    @media (max-width: 400px) {
        font-size: 1.3rem;
    }
`

export const AyahEnglish = styled.div`
    font-weight: 500;
    margin-top: 10px;
    padding: 0px 10px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 0.8rem;
`