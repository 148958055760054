import React, {useState, useEffect} from 'react'
import { NavContainer, NavInnerContainer, NavLeftContainer, NavRightIconContainer, NavRightContainer, NavLogoHolder, NavItemContainer, NavItem, NavExpandedContainer, NavExpandedItemContainer, NavExpandedItem, NavQuoteContainer, NavQuoteItem, NavLeftContainerText} from "./styles/styled.navbar";
import {GrClose} from "react-icons/gr";
import {FiMenu} from "react-icons/fi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "./firebase";
import { useNavigate } from 'react-router-dom';

function Navbar() {

const [showExpanded, setShowExpanded] = useState(false);
const [user, loading, error] = useAuthState(auth);
const navigate = useNavigate();

function handleScroll(item) {
    
  const violation = document.getElementById(item); 
  violation.scrollIntoView({ behavior: 'smooth' });

  setShowExpanded(false);
}

/*
function handleLogout() {
  logout();
}
*/

function handlePage(page) {

  navigate("/" + page)
  setShowExpanded(false);
}

function handleMailClick() {
  window.location.href = "mailto:digitalskydevelopers@gmail.com"
}

async function shareApp() {
  const sharePath = window.location.href;

  const shareData = {
      'title': "Qadha Salaah",
      'text': "Start tracking your qadha salaah's easily with this free app.\n\n",
      'url': sharePath
  }

  try {
      if(navigator.canShare) {
      await navigator.share(shareData)
      } else console.log("Cant share");    
  } catch(err) {
      console.log(err);
    }
      
      
}

  return (
    <NavContainer>
              <NavInnerContainer>
                <NavLeftContainer onClick={() => handlePage("")}>
            
                <NavLeftContainerText>Qadha Salaah</NavLeftContainerText>
                </NavLeftContainer>
                
                <NavRightContainer>
                  
                  <NavRightIconContainer
                    whileHover={{scale: 0.9, opacity: 0.8}}
                    onClick={() => setShowExpanded(prev => !prev)}>
                      {!showExpanded ? <FiMenu size={32} color="black" /> : 
                      <GrClose size={32} color="white" />}
                  </NavRightIconContainer>
                  
                  <NavItemContainer>
                    <NavItem 
                    onClick={() => handlePage("")}>Home</NavItem>
                    <NavItem 
                    onClick={() => handlePage("calculator")}>Qadha Calculator</NavItem>
                    <NavItem onClick={() => shareApp()}>Share</NavItem>
                    
                    <NavItem 
                    onClick={() => handleMailClick()}>Contact</NavItem>
                    {user && <NavItem 
                    onClick={() => logout()}>Logout</NavItem>} 
                  </NavItemContainer>
                
                </NavRightContainer>
              </NavInnerContainer>
              <NavExpandedContainer showExpanded={showExpanded}>
                <NavExpandedItemContainer>
                
                    <NavExpandedItem onClick={() => handlePage("")}>Home</NavExpandedItem>
                    <NavExpandedItem 
                    onClick={() => handlePage("calculator")}>Qadha Calculator</NavExpandedItem>
                    <NavExpandedItem onClick={() => shareApp()}>Share</NavExpandedItem>
                    <NavExpandedItem 
                    onClick={() => handleMailClick()}>Contact</NavExpandedItem>
                    {user && 
                    <NavExpandedItem onClick={() => logout()}>Logout</NavExpandedItem>}
                    
                </NavExpandedItemContainer>
              </NavExpandedContainer>
            </NavContainer>
  )
}

export default Navbar