import React, { useEffect, useState } from 'react'
import { CalculatorContainer, CalculatorItemHolder, CalculatorItem, CalculatorCount, CalculatorSummaryText, CalculatorButton } from './styles/styled.calculator';
import { Container, HeaderTitle, PageContainer, QadhaButtonHolder, QadhaButtonSmall, CalculatorCountButton, QadhaItem, QadhaItemHolder, QadhaSummaryText, QadhaTitle } from './styles/styled.container'



function CalculatorBlock(props) {

    const {title, years, months30, months31, months29, days, 
        setYears, setMonths30, setMonths31, setMonths29, setDays, initialTotal} = props;
    

    const handleFocus = (event) => event.target.select();

  return (
    <CalculatorItemHolder>
                <QadhaTitle style={{'marginBottom': '5px'}}>{title}</QadhaTitle>
                <CalculatorItem>
                    <CalculatorSummaryText>Years</CalculatorSummaryText>
                    <CalculatorCount onFocus={handleFocus} type="number" value={years} onChange={e => setYears(e.target.value)}  />
                </CalculatorItem>
                <CalculatorItem>
                    <CalculatorSummaryText>Months (30 days)</CalculatorSummaryText>
                    <CalculatorCount onFocus={handleFocus} type="number" value={months30} onChange={e => setMonths30(e.target.value)}  />
                </CalculatorItem>
                <CalculatorItem>
                    <CalculatorSummaryText>Months (31 days)</CalculatorSummaryText>
                    <CalculatorCount onFocus={handleFocus} type="number" value={months31} onChange={e => setMonths31(e.target.value)}  />
                </CalculatorItem>
                <CalculatorItem>
                    <CalculatorSummaryText>Months (29 days)</CalculatorSummaryText>
                    <CalculatorCount onFocus={handleFocus} type="number" value={months29} onChange={e => setMonths29(e.target.value)}  />
                </CalculatorItem>
                <CalculatorItem>
                    <CalculatorSummaryText>Days</CalculatorSummaryText>
                    <CalculatorCount onFocus={handleFocus} type="number" value={days} onChange={e => setDays(e.target.value)}  />
                </CalculatorItem>
                <QadhaSummaryText style={{'marginTop': '20px'}}>Total {title} Qadha is {initialTotal}</QadhaSummaryText>

        </CalculatorItemHolder>
  )
}

export default CalculatorBlock