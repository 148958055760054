import { BiShow, BiHide } from "react-icons/bi";
import styled from "styled-components";
import { Colors } from "./styled.container";

export const LoginPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

export const LoginContainer = styled.div`
    background-color: ${Colors.light_grey};
    width: 80%;
    max-width: 500px;
    border-radius: 10px;
    padding: 30px 50px;
    
    margin-top: 20px;
    display: flex;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    
`

export const LoginHeader = styled.h2`
    font-size: 1.5rem;
    font-weight: 800;
    color: ${Colors.dark_grey};
    margin: 10px 0px;

`

export const LoginSubHeader = styled.p`
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    color: ${Colors.dark_grey};
`


export const LoginItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const LoginItemHolder = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
    
    position: relative;
    
`

export const LoginText = styled.p`
    font-size: 0.7rem;
    width: 100%;
    margin-right: 40px;
    text-align: left;
    margin-bottom: 5px;
`

export const LoginInput = styled.input`
    border: 0;
    outline: 0;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    background-color: ${Colors.white};
    font-size: 0.8rem;
` 
export const LoginButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-top: 20px;
    justify-content: space-around;
`


export const LoginButton = styled.div`
    background-color: ${Colors.dark_highlight};
    padding: 10px;
    padding-left: 15px;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: inline-block;
    padding-right: 15px;
    font-size: 0.8rem;
    cursor: pointer;
    color: ${Colors.dark_grey};
`
export const ShowPasswordIcon = styled(BiShow)`
    margin-right: -25px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
`

export const HidePasswordIcon = styled(BiHide)`
    margin-right: -25px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
`

export const LoginLink = styled.p`
    font-size: 0.7rem;
    width: 100%;
    display: flex;
    text-align: center;
    margin-bottom: 5px;
    color: ${Colors.dark_grey};
    cursor: pointer;
    
    ${({bold}) => bold && `
        font-weight: 600;
    `}

    ${({centered}) => centered && `
        justify-content: center;
    `}

    ${({left}) => left && `
        justify-content: start;
    `}

    ${({right}) => right && `
        justify-content: end;
    `}

`