import styled from "styled-components";
import { Colors } from "./styled.container";

export const StyledFooter = styled.footer`
    display: flex;
    width: 100%;
    position: sticky;
    top: 0;
    flex-direction: column;
    background-color: ${Colors.dark_highlight};
    color: ${Colors.dark_grey};
    font-size: 0.6rem;
    font-weight: 500;
    padding: 10px 20px;

    cursor: pointer;

    > p {
        margin: 2px 0px;
        padding: 0;
    }
`
export const FooterSign = styled.p`

    font-weight: 600;

    &:hover {
        cursor: pointer;
    }
`