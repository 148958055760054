import React, {useEffect, useState} from 'react'
import { LoginPageContainer, LoginHeader, LoginSubHeader, LoginContainer, LoginItemHolder, LoginText, LoginInput, LoginItemContainer, LoginButtonContainer, LoginButton, LoginLink } from './styles/styled.login'
import {auth, registerAdminWithEmailAndPassword, createStoreDB} from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { ShowPasswordIcon, HidePasswordIcon } from './styles/styled.login';
import { db } from "./firebase";
import {collection, onSnapshot, query, doc, setDoc, deleteDoc, addDoc, getDoc, updateDoc} from "firebase/firestore";
import Loader from './Loader';
import { ErrorText } from './styles/styled.container';

function Register() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      setShowLoading(true)
      return;
    }
    if (user) navigate("/");
  }, [user, loading]);

  useEffect(() => {
    setShowLoading(false);
  }, [error])

  function handleShowPassword() {
    setShowPassword(prevState => !prevState);
  }

  function checkFields() {
    if(email.length === 0 || (email.indexOf("@") === -1)) return false;
    if(password.length === 0 || confirmPassword.length === 0) return false;

    return true;
  }

  function checkPasswords() {
    if(password !== confirmPassword) return false;
    return true;
  }

  function backToLogin() {
    navigate("/login");
  }

  async function createUser() {

    if(!checkPasswords()) {
        setError("Your passwords do not match.")
        return;
    }

    setShowLoading(true);

    const emailTrimmed = email.toLowerCase().trim();
    const passwordTrimmed = password.trim();

    // create the organisation and admin user
    const status = await registerAdminWithEmailAndPassword(emailTrimmed, passwordTrimmed)
    if(status) {
        setError(status);
        setShowLoading(false);
    }
  }

  return (
    <LoginPageContainer>
        <Loader show={showLoading} />
        <LoginHeader>Qadha Salaah</LoginHeader>
        <LoginSubHeader>Sign up for free and track your qadha's</LoginSubHeader>
        <LoginContainer>
            <LoginItemContainer>
            <LoginItemHolder>
                <LoginText>Email</LoginText>
                <LoginInput value={email} onChange={e => setEmail(e.target.value)}></LoginInput>
            </LoginItemHolder>
            <LoginItemHolder>
                <LoginText>Password</LoginText>
                <LoginInput type={showPassword ? "text" : "password"} value={password} onChange={e => setPassword(e.target.value)}></LoginInput>
                {showPassword ? <HidePasswordIcon onClick={handleShowPassword} /> : <ShowPasswordIcon onClick={handleShowPassword} />}
            </LoginItemHolder>
            <LoginItemHolder>
                <LoginText>Confirm Password</LoginText>
                <LoginInput type={showPassword ? "text" : "password"} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}></LoginInput>
            </LoginItemHolder>
            
            {error && <ErrorText>{error}</ErrorText>}
            </LoginItemContainer>

            <LoginButtonContainer>
                <LoginButton style={{'marginBottom': '20px'}} onClick={() => createUser()}>Sign Up</LoginButton>
                <LoginLink centered onClick={() => backToLogin()}>Already a member? Click here to login</LoginLink>
            </LoginButtonContainer>
            
        </LoginContainer>
    </LoginPageContainer>
  )
}

export default Register