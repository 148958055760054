import React, { useEffect, useState, useRef } from 'react'
import { AyahArabic, AyahEnglish, ButtonApply, Container, HeaderTitle, InstallButton, PageContainer, QadhaButtonHolder, QadhaButtonSmall, QadhaCount, QadhaCountButton, QadhaItem, QadhaItemHolder, QadhaSummaryHolder, QadhaTitle } from './styles/styled.container'
import Qadha from './Qadha'
import QadhaSummary from './QadhaSummary'
import {doc, setDoc, updateDoc} from "firebase/firestore";
import { auth, db } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Loader from './Loader';
import moment from 'moment';
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';
import EmptyTotalBlock from './EmptyTotalBlock';
import InstallButtonBlock from './InstallButtonBlock';

function Home(props) {

    const {salaahElements, setSalaahElements, prompt, promptToInstall} = props;
    const [user, loading, error] = useAuthState(auth);
    const [installReady, setInstallReady] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const pageRef = useRef();
    //const [showInstall, setShowInstall] = useState(false);

    const [total, setTotal] = useState(0);
    useEffect(() => {
        if(salaahElements) {
            setTotal(salaahElements.fajrTotal + salaahElements.dhuhrTotal + salaahElements.asrTotal + salaahElements.maghribTotal + salaahElements.eshaTotal + salaahElements.witrTotal)
        }
    }, [salaahElements])

    useEffect(() => {
        setShowLoading(loading)
      }, [user, loading]);

    useEffect(() => {
        if(prompt)
            setInstallReady(() => true);
    }, [prompt, promptToInstall])
    
    function handleAddDay() {
        setSalaahElements(prevState => ({...prevState, 
            fajrTotal: prevState.fajrTotal + 1,
            dhuhrTotal: prevState.dhuhrTotal + 1,
            asrTotal: prevState.asrTotal + 1,
            maghribTotal: prevState.maghribTotal + 1,
            eshaTotal: prevState.eshaTotal + 1,
            witrTotal: prevState.witrTotal  + 1
        }))
    }

    function handleRemoveDay() {

        setSalaahElements(prevState => ({...prevState, 
            fajrTotal: prevState.fajrTotal > 0 ? prevState.fajrTotal - 1 : prevState.fajrTotal,
            dhuhrTotal: prevState.dhuhrTotal > 0 ? prevState.dhuhrTotal -1 : prevState.dhuhrTotal,
            asrTotal: prevState.asrTotal > 0 ? prevState.asrTotal - 1 : prevState.asrTotal,
            maghribTotal: prevState.maghribTotal > 0 ? prevState.maghribTotal - 1 : prevState.maghribTotal,
            eshaTotal: prevState.eshaTotal > 0 ? prevState.eshaTotal - 1 : prevState.eshaTotal,
            witrTotal: prevState.witrTotal > 0 ? prevState.witrTotal  - 1 : prevState.witrTotal
        }))
    }

    const onScroll = () => {

        console.log("Scrolling")
        if (pageRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = pageRef.current;
          if (scrollTop + clientHeight === scrollHeight) {
            
            console.log('Reached bottom')
          }
        }
    };

    async function updateQadhaCloud() {
        setShowLoading(true);
        const dateToday = moment().format("DD-MM-YYYY");

        await setSalaahElements(prevState => ({...prevState, lastUpdated: dateToday}));
        
        await setDoc(doc(db, "users/" + user.email),  salaahElements, {merge: true});
        await updateDoc(doc(db, "users/" + user.email), { lastUpdated: dateToday }, {merge: true});
        setShowLoading(false);
    }

    // function handleInstall() {
    //     console.log(deferredPrompt);
    //     deferredPrompt.prompt();
    //     // Wait for the user to respond to the prompt
    //     deferredPrompt.userChoice.then((choiceResult) => {
    //       if (choiceResult.outcome === 'accepted') {
    //         console.log('User accepted the A2HS prompt');
    //       } else {
    //         console.log('User dismissed the A2HS prompt');
    //       }
    //     });
    // }

  return (
    <>
    <AddToHomeScreen />
    <PageContainer>
        <Loader show={showLoading} />
        <Container ref={pageRef} onScroll={() => onScroll()}>
            
            {total === 0 && <EmptyTotalBlock />}
            {installReady && <InstallButtonBlock prompt={prompt} promptToInstall={promptToInstall} />} 
            <QadhaSummary total={total} addDay={handleAddDay} removeDay={handleRemoveDay} lastUpdated={salaahElements.lastUpdated} />
            
            <Qadha title="Fajr" salaahElements={salaahElements} setSalaahElements={setSalaahElements} total={salaahElements.fajrTotal} />
            <Qadha title="Dhuhr" salaahElements={salaahElements} setSalaahElements={setSalaahElements} total={salaahElements.dhuhrTotal} />
            <Qadha title="Asr" salaahElements={salaahElements} setSalaahElements={setSalaahElements} total={salaahElements.asrTotal} />
            <Qadha title="Maghrib" salaahElements={salaahElements} setSalaahElements={setSalaahElements} total={salaahElements.maghribTotal} />
            <Qadha title="Esha" salaahElements={salaahElements} setSalaahElements={setSalaahElements} total={salaahElements.eshaTotal} />
            <Qadha title="Witr" salaahElements={salaahElements} setSalaahElements={setSalaahElements} total={salaahElements.witrTotal} />
            
        </Container>
    
        <ButtonApply onClick={() => updateQadhaCloud()}>Click to Save Changes</ButtonApply>
    </PageContainer>
    
    </>
    
  )
}

export default Home