import React, { useEffect, useState } from 'react'
import { CalculatorContainer, CalculatorItemHolder, CalculatorItem, CalculatorCount, CalculatorSummaryText, CalculatorButton } from './styles/styled.calculator';
import { Container, HeaderTitle, PageContainer, QadhaButtonHolder, QadhaButtonSmall, CalculatorCountButton, QadhaItem, QadhaItemHolder, QadhaSummaryText, QadhaTitle } from './styles/styled.container'
import CalculatorBlock from './CalculatorBlock';
import { CalculatorButtonHolder } from './styles/styled.calculator';
import { QadhaSummaryButton, QadhaSummaryHolder  } from './styles/styled.container'
import { useNavigate } from 'react-router-dom';

function Calculator(props) {
    const {salaahElements, setSalaahElements} = props;
    const navigate = useNavigate();

    const [initialFajrTotal, setInitialFajrTotal] = useState(0);
    const [initialDhuhrTotal, setInitialDhuhrTotal] = useState(0);
    const [initialAsrTotal, setInitialAsrTotal] = useState(0);
    const [initialMaghribTotal, setInitialMaghribTotal] = useState(0);
    const [initialEshaTotal, setInitialEshaTotal] = useState(0);
    const [initialWitrTotal, setInitialWitrTotal] = useState(0);
    const [initialTotal, setInitialTotal] = useState(0);

    const [fajrYears, setFajrYears] = useState(0);
    const [fajrMonths30, setFajrMonths30] = useState(0);
    const [fajrMonths31, setFajrMonths31] = useState(0);
    const [fajrMonths29, setFajrMonths29] = useState(0);
    const [fajrDays, setFajrDays] = useState(0);
    const [dhuhrYears, setDhuhrYears] = useState(0);
    const [dhuhrMonths30, setDhuhrMonths30] = useState(0);
    const [dhuhrMonths31, setDhuhrMonths31] = useState(0);
    const [dhuhrMonths29, setDhuhrMonths29] = useState(0);
    const [dhuhrDays, setDhuhrDays] = useState(0);
    const [asrYears, setAsrYears] = useState(0);
    const [asrMonths30, setAsrMonths30] = useState(0);
    const [asrMonths31, setAsrMonths31] = useState(0);
    const [asrMonths29, setAsrMonths29] = useState(0);
    const [asrDays, setAsrDays] = useState(0);
    const [maghribYears, setMaghribYears] = useState(0);
    const [maghribMonths30, setMaghribMonths30] = useState(0);
    const [maghribMonths31, setMaghribMonths31] = useState(0);
    const [maghribMonths29, setMaghribMonths29] = useState(0);
    const [maghribDays, setMaghribDays] = useState(0);
    const [eshaYears, setEshaYears] = useState(0);
    const [eshaMonths30, setEshaMonths30] = useState(0);
    const [eshaMonths31, setEshaMonths31] = useState(0);
    const [eshaMonths29, setEshaMonths29] = useState(0);
    const [eshaDays, setEshaDays] = useState(0);
    const [witrYears, setWitrYears] = useState(0);
    const [witrMonths30, setWitrMonths30] = useState(0);
    const [witrMonths31, setWitrMonths31] = useState(0);
    const [witrMonths29, setWitrMonths29] = useState(0);
    const [witrDays, setWitrDays] = useState(0);

    useEffect(() => {
        if(fajrYears <= 0) setFajrYears(0);
        if(fajrMonths30 <= 0) setFajrMonths30(0);
        if(fajrMonths31 <= 0) setFajrMonths31(0);
        if(fajrMonths29 <= 0) setFajrMonths29(0);
        if(fajrDays <= 0) setFajrDays(0);

        setInitialFajrTotal((fajrYears * 365) + (fajrMonths30 * 30) + (fajrMonths31 * 31) + (fajrMonths29 * 29) + (fajrDays*1));
    }, [fajrYears, fajrMonths30, fajrMonths31, fajrMonths29 ,fajrDays]);

    useEffect(() => {
        if(dhuhrYears <= 0) setDhuhrYears(0);
        if(dhuhrMonths30 <= 0) setDhuhrMonths30(0);
        if(dhuhrMonths31 <= 0) setDhuhrMonths31(0);
        if(dhuhrMonths29 <= 0) setDhuhrMonths29(0);
        if(dhuhrDays <= 0) setDhuhrDays(0);

        setInitialDhuhrTotal((dhuhrYears * 365) + (dhuhrMonths30 * 30) + (dhuhrMonths31 * 31) + (dhuhrMonths29 * 29) + (dhuhrDays*1));
    }, [dhuhrYears, dhuhrMonths30, dhuhrMonths31, dhuhrMonths29 ,dhuhrDays]);

    useEffect(() => {
        if(asrYears <= 0) setAsrYears(0);
        if(asrMonths30 <= 0) setAsrMonths30(0);
        if(asrMonths31 <= 0) setAsrMonths31(0);
        if(asrMonths29 <= 0) setAsrMonths29(0);
        if(asrDays <= 0) setAsrDays(0);

        setInitialAsrTotal((asrYears * 365) + (asrMonths30 * 30) + (asrMonths31 * 31) + (asrMonths29 * 29) + (asrDays*1));
    }, [asrYears, asrMonths30, asrMonths31, asrMonths29 , asrDays]);

    useEffect(() => {
        if(maghribYears <= 0) setMaghribYears(0);
        if(maghribMonths30 <= 0) setMaghribMonths30(0);
        if(maghribMonths31 <= 0) setMaghribMonths31(0);
        if(maghribMonths29 <= 0) setMaghribMonths29(0);
        if(maghribDays <= 0) setMaghribDays(0);

        setInitialMaghribTotal((maghribYears * 365) + (maghribMonths30 * 30) + (maghribMonths31 * 31) + (maghribMonths29 * 29) + (maghribDays*1));
    }, [maghribYears, maghribMonths30, maghribMonths31, maghribMonths29 , maghribDays]);

    useEffect(() => {
        if(eshaYears <= 0) setEshaYears(0);
        if(eshaMonths30 <= 0) setEshaMonths30(0);
        if(eshaMonths31 <= 0) setEshaMonths31(0);
        if(eshaMonths29 <= 0) setEshaMonths29(0);
        if(eshaDays <= 0) setEshaDays(0);

        setInitialEshaTotal((eshaYears * 365) + (eshaMonths30 * 30) + (eshaMonths31 * 31) + (eshaMonths29 * 29) + (eshaDays*1));
    }, [eshaYears, eshaMonths30, eshaMonths31, eshaMonths29 , eshaDays]);

    useEffect(() => {
        if(witrYears <= 0) setWitrYears(0);
        if(witrMonths30 <= 0) setWitrMonths30(0);
        if(witrMonths31 <= 0) setWitrMonths31(0);
        if(witrMonths29 <= 0) setWitrMonths29(0);
        if(witrDays <= 0) setWitrDays(0);

        setInitialWitrTotal((witrYears * 365) + (witrMonths30 * 30) + (witrMonths31 * 31) + (witrMonths29 * 29) + (witrDays*1));
    }, [witrYears, witrMonths30, witrMonths31, witrMonths29 , witrDays]);

    useEffect(() => {
        setInitialTotal(initialFajrTotal + initialDhuhrTotal + initialAsrTotal + initialMaghribTotal + initialEshaTotal + initialWitrTotal);

    }, [initialFajrTotal, initialDhuhrTotal, initialAsrTotal, initialMaghribTotal, initialEshaTotal, initialWitrTotal]);

    
    function resetFields() {
        setFajrYears(0);
        setFajrMonths29(0);
        setFajrMonths30(0);
        setFajrMonths31(0);
        setFajrDays(0);
        
        setDhuhrYears(0);
        setDhuhrMonths29(0);
        setDhuhrMonths30(0);
        setDhuhrMonths31(0);
        setDhuhrDays(0);

        setAsrYears(0);
        setAsrMonths29(0);
        setAsrMonths30(0);
        setAsrMonths31(0);
        setAsrDays(0);

        setMaghribYears(0);
        setMaghribMonths29(0);
        setMaghribMonths30(0);
        setMaghribMonths31(0);
        setMaghribDays(0);

        setEshaYears(0);
        setEshaMonths29(0);
        setEshaMonths30(0);
        setEshaMonths31(0);
        setEshaDays(0);

        setWitrYears(0);
        setWitrMonths29(0);
        setWitrMonths30(0);
        setWitrMonths31(0);
        setWitrDays(0);
    }

    function applyQadhaTotals() {

        setSalaahElements(prevState => ({...prevState, 
            fajrInitial: initialFajrTotal,
            dhuhrInitial: initialDhuhrTotal,
            asrInitial: initialAsrTotal,
            maghribInitial: initialMaghribTotal,
            eshaInitial: initialEshaTotal,
            witrInitial: initialWitrTotal,
            fajrTotal: initialFajrTotal,
            dhuhrTotal: initialDhuhrTotal,
            asrTotal: initialAsrTotal,
            maghribTotal: initialMaghribTotal,
            eshaTotal: initialEshaTotal,
            witrTotal: initialWitrTotal
        }))

        navigate("/");
    }

  return (
    <CalculatorContainer>

        <QadhaSummaryHolder calculator>
        <QadhaTitle>Qadha Calculator</QadhaTitle>
        <QadhaSummaryText style={{'marginTop': '10px'}}>You have {initialTotal} qadha's calculated.</QadhaSummaryText>
    
        </QadhaSummaryHolder>

        <CalculatorBlock title="Fajr" years={fajrYears} months30={fajrMonths30} months31={fajrMonths31}
            months29={fajrMonths29} days={fajrDays}
            setYears={setFajrYears} setMonths30={setFajrMonths30} setMonths31={setFajrMonths31} 
            setMonths29={setFajrMonths29} setDays={setFajrDays} initialTotal={initialFajrTotal} />

        <CalculatorBlock title="Dhuhr" years={dhuhrYears} months30={dhuhrMonths30} months31={dhuhrMonths31}
            months29={dhuhrMonths29} days={dhuhrDays}
            setYears={setDhuhrYears} setMonths30={setDhuhrMonths30} setMonths31={setDhuhrMonths31} 
            setMonths29={setDhuhrMonths29} setDays={setDhuhrDays} initialTotal={initialDhuhrTotal} />

        <CalculatorBlock title="Asr" years={asrYears} months30={asrMonths30} months31={asrMonths31}
            months29={asrMonths29} days={asrDays}
            setYears={setAsrYears} setMonths30={setAsrMonths30} setMonths31={setAsrMonths31} 
            setMonths29={setAsrMonths29} setDays={setAsrDays} initialTotal={initialAsrTotal} />
        
        <CalculatorBlock title="Maghrib" years={maghribYears} months30={maghribMonths30} months31={maghribMonths31}
            months29={maghribMonths29} days={maghribDays}
            setYears={setMaghribYears} setMonths30={setMaghribMonths30} setMonths31={setMaghribMonths31} 
            setMonths29={setMaghribMonths29} setDays={setMaghribDays} initialTotal={initialMaghribTotal} />
        
        <CalculatorBlock title="Esha" years={eshaYears} months30={eshaMonths30} months31={eshaMonths31}
            months29={eshaMonths29} days={eshaDays}
            setYears={setEshaYears} setMonths30={setEshaMonths30} setMonths31={setEshaMonths31} 
            setMonths29={setEshaMonths29} setDays={setEshaDays} initialTotal={initialEshaTotal} />

        <CalculatorBlock title="Witr" years={witrYears} months30={witrMonths30} months31={witrMonths31}
            months29={witrMonths29} days={witrDays}
            setYears={setWitrYears} setMonths30={setWitrMonths30} setMonths31={setWitrMonths31} 
            setMonths29={setWitrMonths29} setDays={setWitrDays} initialTotal={initialWitrTotal} />

        <CalculatorButtonHolder>
            <CalculatorButton onClick={() => resetFields()}>Reset</CalculatorButton>
            <CalculatorButton onClick={() => applyQadhaTotals()}>Apply</CalculatorButton>
        </CalculatorButtonHolder>
            

    </CalculatorContainer>
  )
}

export default Calculator