import React from 'react'
import { EmptyTotalContainer, EmptyContainerButton, EmptyContainerText, QadhaTitle } from './styles/styled.container'
import { useNavigate } from 'react-router-dom'

function EmptyTotalBlock() {
    const navigate = useNavigate();

  return (
    <EmptyTotalContainer>
        <QadhaTitle>Get Started</QadhaTitle>
        <EmptyContainerText>Calculate your total qadha's due by clicking below</EmptyContainerText>
        <EmptyContainerButton onClick={() => navigate("/calculator")}>Calculator</EmptyContainerButton>
    </EmptyTotalContainer>
  )
}

export default EmptyTotalBlock