// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, query,
  getDocs,
  doc,
  collection,
  where,
  addDoc,
  setDoc} from "firebase/firestore";

import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  } from "firebase/auth";

import { getMessaging, getToken, onMessage} from "firebase/messaging";
import moment from "moment/moment";

  const firebaseConfig = {
    apiKey: "AIzaSyAuqt2by2Ng_nbKcb-1uyww7duETBo6JGQ",
    authDomain: "qadha-3ddbd.firebaseapp.com",
    projectId: "qadha-3ddbd",
    storageBucket: "qadha-3ddbd.appspot.com",
    messagingSenderId: "655637112183",
    appId: "1:655637112183:web:34a45eaace4c07bf0ead72",
    measurementId: "G-XGCF31T5GX"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
export const FBmessaging = getMessaging(app);

export const fetchToken = async (setToken) => {
  await getToken(FBmessaging, {
    vapidKey: "BFOwl93WGNj6lojf5tCa4NxcDWzj9FhcNgpaw6TfVb_GCBwQ8_FVOe4oXzlY3A5PKCiOFshoiowOpEZoOwwWIBI",
  })
    .then((currentToken) => {
      if (currentToken) {
        setToken(currentToken);
        console.log("currentToken: ", currentToken);
      } else {
        console.log("No registration token available. Request permission to generate one.");
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);

  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {

    const q = query(collection(db, "users"), where("email", "==", email));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      return "User does not exist. Please sign up."
    }
    /*
    else {
      docs.forEach(doc => {
        const displayName = doc.data().name;
        localStorage.setItem("displayName", displayName);
      })
    }
    */

    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    return err.message;
  }
};
const registerAdminWithEmailAndPassword = async (email, password) => {
  try {

    console.log("Creating user " + email);
    const dateToday = moment().format("DD-MM-YYYY");

    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    console.log("User login details created");

    await setDoc(doc(db, "users", email), {
        uid: user.uid,
        authProvider: "local",
        email,
        lastUpdated: dateToday,
        fajrInitial: 0,
        dhuhrInitial: 0,
        asrInitial: 0,
        maghribInitial: 0,
        eshaInitial: 0,
        witrInitial: 0,
        fajrTotal: 0,
        dhuhrTotal: 0,
        asrTotal: 0,
        maghribTotal: 0,
        eshaTotal: 0,
        witrTotal: 0
    })

    console.log("User added to store");

  }catch(err) {
    console.log(err.message);
    return err.message;
  }
};


const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return "Your password reset instructions have been emailed to you."
  } catch (err) {
    return err.message;
  }
};

const logout = () => {
  signOut(auth);
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(FBmessaging, (payload) => {
      resolve(payload);
    });
});



export {db, auth, signInWithGoogle, getToken,
  logInWithEmailAndPassword,
  registerAdminWithEmailAndPassword,
  sendPasswordReset,
  logout};