import styled from "styled-components"
import { keyframes } from "styled-components"
import { Colors } from "./styled.container"

const frames = keyframes`
0% { transform: rotate(0deg);}
100% { transform: rotate(360deg); }
`

export const SpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Spinner = styled.div`
    width: 50px;
    height: 50px;
    border: 5px solid ${Colors.dark_highlight}; /* Light grey */
    border-top: 5px solid aliceblue; /* Black */
    border-radius: 50%;
    animation: ${frames} 1s linear infinite;
`