import React, { useEffect, useState } from 'react'
import { LoginPageContainer, LoginContainer, LoginItemHolder, LoginText, LoginInput, LoginItemContainer, LoginButtonContainer, LoginButton, LoginLink, LoginHeader, LoginSubHeader } from './styles/styled.login'
import { auth, logInWithEmailAndPassword} from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { ErrorText } from './styles/styled.container';
import { ShowPasswordIcon, HidePasswordIcon } from "./styles/styled.login"
import Loader from './Loader';
import {doc, getDoc} from "firebase/firestore";
import { db } from "./firebase";

function Login() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      setShowLoading(true)
      return;
    }
    if (user) navigate("/");
  }, [user, loading]);

  useEffect(() => {
    setShowLoading(false);
  }, [error])

  function handleShowPassword() {
    setShowPassword(prevState => !prevState);
  }

  function handleForgotPassword() {
    navigate("/resetpassword")
  }

  function checkFields() {
    if (email.length === 0 || (email.indexOf("@") === -1)) return false;
    if (password.length === 0) return false;

    return true;
  }

  async function handleLogin() {
    if (!checkFields()) {
      setError("Please fill in all fields before continuing.")
      return;
    }

    const emailTrimmed = email.toLowerCase().trim();
    const passwordTrimmed = password.trim();

    setShowLoading(true);

    const status = await logInWithEmailAndPassword(emailTrimmed, passwordTrimmed)
    if(status) {
        setError(status);
        setShowLoading(false);
    }
  }

  return (
    <LoginPageContainer>
      <Loader show={showLoading} />
      <LoginHeader>Qadha Salaah</LoginHeader>
      <LoginSubHeader>Keep track of your qadha's easily.</LoginSubHeader>
      <LoginContainer>
        <LoginItemContainer>
          <LoginItemHolder>
            <LoginText>Email</LoginText>
            <LoginInput value={email} onChange={e => setEmail(e.target.value)}></LoginInput>
          </LoginItemHolder>
          <LoginItemHolder>
            <LoginText>Password</LoginText>
            <LoginInput type={showPassword ? "text" : "password"} value={password} onChange={e => setPassword(e.target.value)}></LoginInput>
            {showPassword ? <HidePasswordIcon onClick={handleShowPassword} /> : <ShowPasswordIcon onClick={handleShowPassword} />}
          </LoginItemHolder>
          {error && <ErrorText>{error}</ErrorText>}
        </LoginItemContainer>

        <LoginButtonContainer>
          <LoginButton style={{'marginBottom': '20px'}} onClick={() => handleLogin()}>Login</LoginButton>
          <LoginLink centered onClick={() => handleForgotPassword()}>Forgot your password?</LoginLink>
          <LoginLink style={{'fontSize': '0.8rem'}} bold centered onClick={() => navigate("/register")}>Sign up for Free</LoginLink>
        </LoginButtonContainer>

      </LoginContainer>

    </LoginPageContainer>
  )
}

export default Login