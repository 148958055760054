import logo from './logo.svg';
import './App.css';
import Home from './Home';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Calculator from './Calculator';
import Navbar from './Navbar';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, fetchToken, logout, onMessageListener } from "./firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { useAddToHomescreenPrompt } from './useAddToHomescreenPrompt';

function App() {

    const [fajrTotal, setFajrTotal] = useState(0);
    const [dhuhrTotal, setDhuhrTotal] = useState(0);
    const [asrTotal, setAsrTotal] = useState(0);
    const [maghribTotal, setMaghribTotal] = useState(0);
    const [eshaTotal, setEshaTotal] = useState(0);
    const [witrTotal, setWitrTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [user, loading, error] = useAuthState(auth);

    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});
    const [isTokenFound, setTokenFound] = useState(false);
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();

    const [salaahElements, setSalaahElements] = useState({

      fajrInitial: 0,
      dhuhrInitial: 0,
      asrInitial: 0,
      maghribInitial: 0,
      eshaInitial: 0,
      witrInitial: 0,
      fajrTotal: 0,
      dhuhrTotal: 0,
      asrTotal: 0,
      maghribTotal: 0,
      eshaTotal: 0,
      witrTotal: 0,
    });

    const navigate = useNavigate();

    useEffect(() => {
      fetchToken(setTokenFound);

      // window.addEventListener('beforeinstallprompt', (e) => {
      //   deferredPrompt = e;
      //   console.log(e);
      // });
    }, [])

    useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/login");
  
      fetchUser();
    }, [user, loading]);

    useEffect(() => {
        setTotal(fajrTotal + dhuhrTotal + asrTotal + maghribTotal + eshaTotal + witrTotal);
    }, [fajrTotal, dhuhrTotal, asrTotal, maghribTotal, eshaTotal, witrTotal])

    const fetchUser = async () => {
      try {
        const q = query(collection(db, "users"), where("uid", "==", user?.uid));
        const doc = await getDocs(q);
        const data = doc.docs[0].data();
  
        setSalaahElements(data)
      } catch (err) {
        console.error(err);
      }
    };

    onMessageListener().then(payload => {
      setNotification({title: payload.notification.title, body: payload.notification.body})
      setShow(true);
      console.log("In App");
      console.log(payload);
    }).catch(err => console.log('failed: ', err));

    const onShowNotificationClicked = () => {
      setNotification({title: "Notification", body: "This is a test notification"})
      setShow(true);
    }

  return (
    <>
    <Navbar />
    <Routes>
      <Route path='*' element={<Home salaahElements={salaahElements} setSalaahElements={setSalaahElements} prompt={prompt} promptToInstall={promptToInstall} />} />
      <Route path="/calculator" element={<Calculator salaahElements={salaahElements} setSalaahElements={setSalaahElements} />} />
    </Routes>
    </>
    
  );
}

export default App;
