import React, {useEffect, useState} from 'react'
import { StyledFooter } from './styles/styled.footer'

function Footer() {

    function handleMailClick() {
        window.location.href = "mailto:digitalskydevelopers@gmail.com"
    }

  return (
    <>
    <StyledFooter onClick={() => handleMailClick()}>
        <p>Qadha Salaah. Created for the pleasure of Allah (S.W.T) </p>
        <p>Developed by Digital Sky - digitalskydevelopers@gmail.com</p>
    </StyledFooter>
    </>
  )
}

export default Footer