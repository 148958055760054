import React, { useEffect, useState } from 'react'
import { LoginPageContainer, LoginHeader, LoginSubHeader, LoginContainer, LoginItemHolder, LoginText, LoginInput, LoginItemContainer, LoginButtonContainer, LoginButton, LoginLink } from './styles/styled.login'
import { auth, sendPasswordReset} from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { ErrorText } from './styles/styled.container';
import { ShowPasswordIcon, HidePasswordIcon } from './styles/styled.login';
import Loader from './Loader';
import {doc, getDoc} from "firebase/firestore";
import { db } from "./firebase";

function ResetPassword() {

  const [email, setEmail] = useState("");
  const [user, loading] = useAuthState(auth);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      setShowLoading(true)
      return;
    }
    if (user) navigate("/");
  }, [user, loading]);

  useEffect(() => {
    setShowLoading(false);
  }, [error])

  function checkFields() {
    if (email.length === 0 || (email.indexOf("@") === -1)) return false;

    return true;
  }

  async function handlePasswordReset() {
    if (!checkFields()) {
      setError("Please fill in all fields before continuing.")
      return;
    }

    setShowLoading(true);

    const status = await sendPasswordReset(email)
    if(status) {
        setError(status);
        setShowLoading(false);
    }
  }

  return (
    <LoginPageContainer>
      <Loader show={showLoading} />
      <LoginHeader>Qadha Salaah</LoginHeader>
      <LoginSubHeader>Reset your password</LoginSubHeader>
      <LoginContainer>
        <LoginItemContainer>
          <LoginItemHolder>
            <LoginText>Enter your email</LoginText>
            <LoginInput value={email} onChange={e => setEmail(e.target.value)}></LoginInput>
          </LoginItemHolder>
        </LoginItemContainer>
        {error && <ErrorText style={{'marginBottom': '10px'}}>{error}</ErrorText>}

        <LoginButtonContainer style={{'marginTop': '0px'}}>
          <LoginButton onClick={() => handlePasswordReset()}>Reset Password</LoginButton>
          <LoginLink centered onClick={() => navigate("/login")}>Back to login</LoginLink>
        </LoginButtonContainer>

      </LoginContainer>

    </LoginPageContainer>
  )
}

export default ResetPassword;