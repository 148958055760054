import styled from "styled-components";
import { Colors } from "./styled.container";

export const CalculatorContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1); 
`

export const CalculatorItemHolder = styled.div`
    width: 90%;
    height: fit-content;
    padding: 10px 0px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    max-width: 600px;
    display: flex;
    flex-direction: column;

    border-radius: 10px;
    background-color: ${Colors.card_background};
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`

export const CalculatorItem = styled.div`
    width: 100%;
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`

export const CalculatorSummaryText = styled.div`
    flex: 1;
    font-size: 0.9rem;

    @media(max-width: 400px) {
        font-size: 0.7rem;
    }
`

export const CalculatorButtonHolder = styled.div`
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
`

export const CalculatorButton = styled.div`
    margin-top: 0px 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    flex: 1;
    margin: 10px;
    text-align: center;
    padding: 10px;
    font-size: 0.9rem;
    font-weight: 600;
    background-color: ${Colors.dark_highlight};
    color: ${Colors.dark_grey};
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-tap-highlight-color: transparent;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

    &:hover {
        color: ${Colors.white};
    }
`

export const CalculatorCount = styled.input`
    background-color: ${Colors.white};
    flex: 1;
    border-radius: 10px;
    text-align: center;
    border: 0;
    outline: 0;
    font-size: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    @media (max-width: 500px) {
        width: 120px;
        font-size: 1.2rem;
    }
`
