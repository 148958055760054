import React from 'react'
import { SpinnerContainer, Spinner } from './styles/styled.spinner'

function Loader({show}) {
  return (
    <>
    {show && <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>}
    </>
  )
}

export default Loader