import React, {useState, useEffect} from 'react'
import { InstallButton } from './styles/styled.container';

function InstallButtonBlock(props) {
    const {prompt, promptToInstall} = props; 
    const [isVisible, setVisibleState] = useState(false);

    //const hideInstall = () => setVisibleState(false);
    useEffect(() => {
        if (prompt) {
          setVisibleState(true);
        }
      },
    [prompt]);

    if (!isVisible) {
        return <div />;
    }

  return (
    <InstallButton onClick={promptToInstall}>Install to Home Screen</InstallButton>
  )
}

export default InstallButtonBlock