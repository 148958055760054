import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import ResetPassword from "./ResetPassword"
import Footer from './Footer';
import * as serviceWorkerRegister from "./serviceWorkerRegistration"
import ServiceWorkerWrapper from './ServiceWorkerWrapper';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <>
    <ServiceWorkerWrapper />
    <BrowserRouter>
        <Routes>
            <Route path="*" element={<App />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
        </Routes>
    </BrowserRouter>
    
    <Footer />
    </>
);

serviceWorkerRegister.register();


